/* eslint-disable prettier/prettier */

function Navbar() {
  return (
    <nav className="container">
      <a href="/">
        Developer.Me
      </a>
    </nav>
  );
}

export default Navbar;
